let counter = 0;

let scrolling = false;

let previousScroll = 0;

let self = $('html');


//smooth scrolling

$('a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('noScroll');
    $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top
    }, 1500, 'swing');
});


const app = {}


// scroll event 
app.scrollingEvent = () => {
    $(window).scroll(function () {
        scrolling = true;

    })
}

app.scrollEffects = function () {
    setInterval(function () {
        if (scrolling = true) {
            scrolling = false;
            app.lazyLoadArray();
        } // end of if scrolling
    }, 250) // end of interval
};

app.lazyLoad = (imageItem) => {
    let imageTop = $(imageItem).offset().top;
    let imageBottom = $(imageItem).offset().top + $(imageItem).outerHeight();
    let screenBottom = $(window).scrollTop() + window.innerHeight;
    let screenTop = $(window).scrollTop();

    if ((screenBottom > imageTop) && (screenTop < imageBottom)) {
        // setImageSrcFromDataAttribute(imageItem);
        let imageUrl = $(imageItem).data('src');
        $(imageItem).attr('src', imageUrl);


    }
}


app.lazyLoadArray = () => {
    let imageArray = [];
    imageArray = $('.lazyLoad');


    // requestAnimationFrame(app.lazyLoadArray);

    $.each(imageArray, function (index, value) {

        app.lazyLoad(value);



    })// end of loop
}


// mobile menu
app.navOverlayButton = () => {
    $('.menuButton, .overlayNavListItem').on('click', (e) => {
        e.preventDefault();
        $('.headerWrapper').addClass('noScroll');
        let overlayShowing = $('.menuOverlay').hasClass('showNavOverlay');
        if (!overlayShowing) {

            $('.menuButton').addClass('menuOverlayButton');
            
            $('.menuOverlay').addClass('fadeIn');
            setTimeout(() => {
                $('.menuOverlay').addClass('showNavOverlay')
            },100)
            setTimeout(() => {
                $('.menuOverlay').removeClass('fadeIn');
            }, 300);

            setTimeout(() => {
                $('.overlayNavListItem1').addClass('makeOp')
            }, 200);

            setTimeout(() => {
                $('.overlayNavListItem2').addClass('makeOp')
            }, 300);

            setTimeout(() => {
                $('.overlayNavListItem3').addClass('makeOp')
            }, 400);

            setTimeout(() => {
                $('.overlayNavListItem4').addClass('makeOp')
            }, 500);

            setTimeout(() => {
                $('.overlayNavListItem5').addClass('makeOp')
            }, 600);

            setTimeout(() => {
                $('.overlayNavListItem6').addClass('makeOp')
            }, 700);

        } else if (overlayShowing) {
            $('.menuOverlay').addClass('fadeIn');
            setTimeout(() => {
                $('.menuOverlay')
                    .removeClass('fadeIn showNavOverlay');
            }, 300);
            $('.overlayNavListItem').removeClass('makeOp');
            $('.menuButton').removeClass('menuOverlayButton');
            $('.headerWrapper').removeClass('noScroll');

        }
    })
}








// hello Section
app.readMoreBio = () => {
    $('.readMoreBio').on('click', (e) => {
        e.preventDefault();
        $('.hideBio').addClass('showBioElement fadeIn');
        $('.hideBio').removeClass('hideBio');
        $('.readMoreBio').addClass('hideBio');
        setTimeout(() => {
            $('.showBioElement').removeClass('fadeIn');
            $('.threeDots').addClass('hideBio');
        }, 300);

    })
}


// view past performances in the calendar
app.viewMoreCal = () => {
    $('.viewMoreCal').on('click', (e) => {
        e.preventDefault();
        
        $('.hideCalendar').addClass('showCalendar fadeIn');
        $('.hideCalendar').removeClass('hideCalendar');
        setTimeout(() => {
            $('.showCalendar').removeClass('fadeIn');
            $('.viewMoreCal').addClass('hideCalendar');
        }, 300);
    })
}





// *******************MEDIA SECTION********************************

// view more thumbnails
app.viewMoreStage = () => {
    $('.viewAllStage').on('click', (e) => {
        e.preventDefault();

        $('.hideThumb').addClass('showThumb fadeIn');
        $('.hideThumb').removeClass('hideThumb');
        setTimeout(() => {
            $('.showThumb').removeClass('fadeIn');
            $('.viewAllStage').addClass('hideThumb');
        }, 300);
    })
}



// EVENTS THAT MOVE FORWARD THROUGH THE CAROUSEL

// event on pushing the right arrow
app.navigateRight = () => {
    $('.galleryButtonRight').on('click', (e) => {
        e.preventDefault();
        app.navigateGallery(1);

    })
}

// event on swiping left aka moving forward on the gallery
app.swipeLeft = () => {

    $(window).on('swipeleft', function (event) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('hideGallery');
        if (!galleryOverlayVisible) {
            app.navigateGallery(1);

        }
    });
}

// EVENTS THAT MOVE BACKWARDS THROUGH THE CAROUSEL

// event on pushing the left arrow
app.navigateLeft = () => {
    $('.galleryButtonLeft').on('click', (e) => {
        e.preventDefault();
        app.navigateGallery(-1);
    })
}

// event on swiping right aka moving backwards in gallery

app.swipeRight = () => {

    $(window).on('swiperight', function (event) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('hideGallery');
        if (!galleryOverlayVisible) {
            app.navigateGallery(-1);

        }
    });
}



// left and right click navigation through carousel

app.leftRightArrows = () => {
    $("body").keydown(function (e) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('hideGallery');
        if (!galleryOverlayVisible && e.keyCode == 37) { // left
            $('.galleryButtonLeft').trigger('click');
        }
        else if (!galleryOverlayVisible && e.keyCode == 39) { // right
            $('.galleryButtonRight').trigger('click');
        }
    });
}


// OPEN GALLERY OVERLAY 
app.openGalleryOverlay = function () {
    $('.openCarouselLink').on('click', function (e) {
        e.preventDefault();
        let image = $(this).find('img').attr('data-image');
    })
};


// CHANGE BETWEEN GALLERIES

// view portrait gallery
app.viewPortrait = () => {
    $('.viewPortrait').on('click', (e) => {
        e.preventDefault();
        $('.galleryGrid .stageGallery').fadeOut('200');
        setTimeout(() => {
            $('.galleryGrid .portraitGallery').fadeIn('200');
        }, 400);
        // buttons
        $('.viewPortrait').addClass('goldUnderLine');
        $('.viewStage').removeClass('goldUnderLine');

        $('.viewPortrait').addClass('noClick').addClass('selected');
        $('.viewStage').removeClass('noClick').removeClass('selected');

        $('.addHide').addClass('mediaHiddenImage');
        $('.viewMore').removeClass('hideElement');

        //resets counter
        app.navigateGallery('reset');

    })
}

//view stage gallery
app.viewStage = () => {
    $('.viewStage').on('click', (e) => {
        e.preventDefault();

        // fade the gallries in and out when switching between them
        $('.galleryGrid .portraitGallery').fadeOut('200');
        setTimeout(() => {
            $('.galleryGrid .stageGallery').fadeIn('200');
        }, 400);
        // buttons
        $('.viewStage').addClass('noClick goldUnderLine');
        $('.viewPortrait').removeClass('goldUnderLine');

        $('.viewStage').addClass('noClick').addClass('selected')
        $('.viewPortrait').removeClass('noClick').removeClass('selected');

        $('.addHide').addClass('mediaHiddenImage');
        $('.viewMore').removeClass('hideElement');

        //resets counter
        app.navigateGallery('reset');

    })
}


app.navigateGallery = (direction) => {


    let totalSlideNumbers = $('.galleryOverlay .showGallery').children().length;

    // array of pictures
    let carouselItems = $('.galleryOverlay .showGallery').children();

    $('.currentPicture').empty();
    $('.galleryOverlay .showGallery').find('.currentSlide').removeClass('currentSlide');
    counter = counter + direction;

    // go back to the last item of the carousel if clicking left while on the first picture
    if (direction === -1 && counter < 0) {
        counter = totalSlideNumbers - 1;
    }

    // go to the start of the carousel when you reach the end
    if (direction === 1 && !carouselItems[counter]) {
        counter = 0
    }

    if (direction === 'reset') {
        counter = 0
    }

    let displayedPicture = carouselItems[counter];


    $(displayedPicture).addClass('currentSlide');

    let credit = $('.currentSlide').attr('data-src');

    $('.photoCreds ').empty();
    $('.photoCreds ').append(credit);

}



// LAZY LOAD ON THE OVERLAY -> also opens the overlay
app.LoadOverlay = function () {
    $('.openCarouselLink').on('click touch', function (e) {
        e.preventDefault();
        $('body').addClass('noScroll');
        let chosenGallery = $(this).parent().parent().parent();
        let galleryType = $(chosenGallery[0]).attr('class');

        if (galleryType === 'portraitGallery gallery hideGallery') {
            let galleryToOpen = $('.galleryOverlay .portraitGallery')
            openOverlay(galleryToOpen, this);

        } else {
            let galleryToOpen = $('.galleryOverlay .stageGallery')
            openOverlay(galleryToOpen, this);

        }

        lazyLoadImages($('.lazyOverlay'));

    })// end of event handler
} //end of gallery open and lazy load


//function to open the gallery overlay
let openOverlay = (galleryToOpen, chosen) => {
    $(galleryToOpen).addClass('showGallery')
    $('.galleryOverlay').removeClass('hideGallery');

    $('.galleryOverlay .currentSlide').removeClass('currentSlide');

    let clickedImage = $(chosen).find('img').attr('data-image');

    let images = [];
    images = galleryToOpen.find('img');

    $.each(images, function (index, value) {

        let picture = ($(value).attr('data-src'));

        let listItem = ($(value).parent());

        let credit = $(listItem).attr('data-src');


        if (clickedImage === picture) {
            $(value).parent().addClass('currentSlide')

            $('.photoCreds').append(credit)
        }// end of ternary operator 
    })// end of loop
    let direction = $('.galleryOverlay .currentSlide').val();
    counter = 0;
    app.navigateGallery(direction - 1)
}// end of overOverlay


// close the gallery overlay 


app.closeGalleryOverlay = () => {
    $('.closeGalleryOverlay').on('click', (e) => {
        e.preventDefault();
        $('body').removeClass('noScroll');
        // make sure none of the galleries have a showGallery on them by default
        $('.galleryOverlay').addClass('fadeOut');
        setTimeout(() => {
            $('.galleryOverlay')
                .find('.showGallery')
                .removeClass('showGallery')
            $('.galleryOverlay')
                .addClass('hideGallery')
                .removeClass('fadeOut');
        }, 200)
    })
}

app.escapeGalleryOverlay = () => {
    $("body").keydown(function (e) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('hideGallery');
        if (!galleryOverlayVisible && e.keyCode == 27) { // left
            $('.closeGalleryOverlay').trigger('click');
        }
    });
}



// ********** LAZY LOAD ***********

// Set the image src as its data attr
let setImageSrcFromDataAttribute = (imageElement) => {
    let imageUrl = imageElement.data('src');
    imageElement.attr('src', imageUrl);
}

// loop through each of the images
let lazyLoadImages = (imageElements) => {
    imageElements.each(function () {
        setImageSrcFromDataAttribute($(this));
    });
}


// ******** PRESS SECTION ***************

// toggle between news and press 


// view quotes
app.viewQuotes = () => {
    $('.chooseQuotes').on('click', (e) => {
        e.preventDefault();
        $('.pressNews').fadeOut('200');
        setTimeout(() => {
            $('.pressQuotes').fadeIn('200');
        }, 400);
        // buttons

        $('.chooseQuotes').addClass('noClick').addClass('selected');
        $('.chooseFeatures').removeClass('noClick').removeClass('selected');
    })
}

//view news
app.viewNews= () => {
    $('.chooseFeatures').on('click', (e) => {
        e.preventDefault();

        // fade the gallries in and out when switching between them
        $('.pressQuotes').fadeOut('200');
        setTimeout(() => {
            $('.pressNews').fadeIn('200');
        }, 400);
        // buttons

        $('.chooseFeatures').addClass('noClick').addClass('selected')
        $('.chooseQuotes').removeClass('noClick').removeClass('selected');


    })
}


//view more quotes
app.viewMoreQuotes = () => {
    $('.moreQuotes').on('click', (e) => {
        e.preventDefault();

        $('.pressHide, .pressHideMobile').addClass('pressShow fadeIn');
        $('.pressHide, .pressHideMobile').removeClass('pressHide');
        setTimeout(() => {
            $('.pressShow').removeClass('fadeIn');
            $('.moreQuotes').addClass('pressHide');
        }, 300);
    })
}

//view more news
app.viewMoreNews = () => {
    $('.moreNews').on('click', (e) => {
        e.preventDefault();

        $('.newsHide').addClass('pressShow fadeIn').removeClass('pressHide');
        setTimeout(() => {
            $('.pressNews .pressShow').removeClass('fadeIn');
            $('.moreNews').addClass('pressHide');
        }, 300);
    })
}


// ***************  REPERTOIRE SECTION ****************

// select a letter, and have that composer show up in the list
app.chooseLetter = function() {
    $('.chooseLetter').on('click', function(e) {
        e.preventDefault();

        let chosenLetter = $(this).data('src');
        let musicType = $(this).parent().parent().parent();
        
        var composers = [];
        var composer;
        var composerList = [];
        composers = $(musicType).find('.compListItem');
        
        // match letter with artist
        $.each(composers, function (index, value) {

            let composerLetter = ($(value).attr('data-src'));

            if (composerLetter === chosenLetter) {
                composer = $(value).attr('data-comp');
                
                composerList.push(composer);
                
            }// end of ternary operator 
        })// end of loop

        $('.chosenCompList').empty()

        // append artist names
        $.each(composerList, function (index, value) {
            
            $('.chosenCompList').append('<li class="compList"><a class="" href="">' + value + '</a></li>');
        })// end of loop

        // highlight the first artist
        $('.compList a').first().addClass('chosenArtist');



        var chosenComp = $('.chosenArtist').html();

        var composerName;

        var liList = [];

        // past artist's songs
        $.each(composers, function (index, value) {

            composerName = ($(value).attr('data-comp'));

            var liChosen = $(value).find('.songItem');

            if (composerName === chosenComp) {
                
                $.each(liChosen, function (index, value) {

                    let songName = $(value).html();
                    liList.push(songName);
                })


 
            }// end of ternary operator 
        })// end of loop

        //empty song list
        $('.repSongList').empty();

        // append song names
        $.each(liList, function (index, value) {

            $('.repSongList').append('<li class="songListItem">' + value + '</li>');
        })// end of loop
        
        
        
        
        //change the letters between selected and unselected
        $(musicType).find('.chosenLetter').removeClass('chosenLetter');
        $(this).addClass('chosenLetter');

        app.chooseArtist();
    })
}


//when clicking on different artist names
app.chooseArtist = function() {
    $('.compList a').on('click', function(e) {
        e.preventDefault();

        var comps = $('.showRep').find('.compListItem');
        var compToSee = $(this).html();
        var selectedComp;

        // match letter with artist
        $.each(comps, function (index, value) {

            var composerNameII = ($(value).attr('data-comp'));

            if (composerNameII === compToSee) {

                selectedComp = $(value);

            }// end of ternary operator 
        })// end of loop

        var newSongList = [];
        var compSongs = $(selectedComp).find('.songItem');
        
        // put songs into array
        $.each(compSongs, function (index, value) {
            newSongList.push($(value).html());
        })// end of loop
        
        $('.repSongList').empty();

        //paste songs into DOM
        $.each(newSongList, function (index, value) {
            $('.repSongList').append('<li class="songListItem">' + value + '</li>')
        })// end

        $('.chosenArtist').removeClass('chosenArtist');
        $(this).addClass('chosenArtist');
        
    })
}

//choose song section
app.chooseSong = () => {
    $('.chooseSong').on('click', function(e) {
        e.preventDefault();

        //change selectors
        $('.selectedRep').removeClass('selectedRep');
        $(this).addClass('selectedRep');

        // show song section
        $('.showRep').removeClass('showRep');
        $('.songRep').addClass('showRep');

        // show first letter, first artist, and that artist's songs
        $('.songRep .chooseLetter').removeClass('chosenLetter');
        $('.songRep .chooseLetter').first().addClass('chosenLetter');

        $('.chosenCompList').empty();
        $('.chosenCompList').append('<li class="compList"><a class="chosenArtist" href="">Hector Berlioz</a></li><li class="compList"><a class="" href="">Keith Bissell</a></li><li class="compList"><a class="" href="">William Bolcom</a></li><li class="compList"><a class="" href="">Johannes Brahms</a></li><li class="compList"><a class="" href="">Lloyd Burritt</a></li>');

        $('.repSongList').empty();
        $('.repSongList').append('<li class="songItem">La Mort d’Ophélie</li>');


        // give it a margin
        $('.chosenRep').css('margin-top', '0px');

        app.chooseLetter();
        app.chooseArtist();

    })
}

//choose opera section
app.chooseOpera = () => {
    $('.chooseOpera').on('click', function (e) {
        e.preventDefault();

        //change selectors
        $('.selectedRep').removeClass('selectedRep');
        $(this).addClass('selectedRep');

        // show song section
        $('.showRep').removeClass('showRep');
        $('.operaRep').addClass('showRep');

        // show first letter, first artist, and that artist's songs
        $('.operaRep .chooseLetter').first().addClass('chosenLetter');
        $('.operaRep .chooseLetter').removeClass('chosenLetter');

        $('.chosenCompList').empty();
        $('.chosenCompList').append('<li class="compList"><a class="chosenArtist" href="">Benjamin Britten</a></li><li class="compList"><a class="" href="">Lloyd Burritt</a></li>');

        $('.repSongList').empty();
        $('.repSongList').append('<li class="songListItem">Mrs. Herring - <span class="italic">Albert Herring</span></li><li class= "songListItem" > Lucretia <span class= "italic" > The Rape of Lucretia</span ></li>');

        // give it a margin
        $('.chosenRep').css('margin-top', '0px');

        app.chooseLetter();
        app.chooseArtist();

    })
}

//choose oratorio section
app.chooseOra = () => {
    $('.chooseOra').on('click', function (e) {
        e.preventDefault();

        //change selectors
        $('.selectedRep').removeClass('selectedRep');
        $(this).addClass('selectedRep');

        // show song section
        $('.showRep').removeClass('showRep');
        $('.oratorioRep').addClass('showRep');

        // show first letter, first artist, and that artist's songs
        $('.oratorioRep .chooseLetter').removeClass('chosenLetter');
        $('.oratorioRep .chooseLetter').first().addClass('chosenLetter');

        $('.chosenCompList').empty();
        $('.chosenCompList').append('<li class="compList"><a class="chosenArtist" href="">Johann Sebastian Bach</a></li><li class="compList"><a class="" href="">Ludwig van Beethoven</a></li>');

        $('.repSongList').empty();
        $('.repSongList').append('<li class="songListItem">Mass in B Minor</li><li class="songListItem"><span class="italic">Magnificat</span></li><li class="songListItem">BWV 82</li><li class="songListItem">BWV 147</li>');

        // give it a margin
        $('.chosenRep').css('margin-top', '0px');

        app.chooseLetter();
        app.chooseArtist();

    })
}

//choose oratorio section
app.chooseMus = () => {
    $('.chooseMus').on('click', function (e) {
        e.preventDefault();

        //change selectors
        $('.selectedRep').removeClass('selectedRep');
        $(this).addClass('selectedRep');

        // show song section
        $('.showRep').removeClass('showRep');
        $('.musicalRep').addClass('showRep');


        $('.chosenCompList').empty();
        $('.chosenCompList').append('<li class="compList"><a class="chosenArtist italic" href="">Les Misérables</a></li><li class="compList"><a class=" italic" href="">Sweeney Todd</a></li><li class="compList"><a class=" italic" href="">Into the Woods</a></li>');

        $('.repSongList').empty();
        $('.repSongList').append('<li class="songListItem">Madame Thernardier</li>');

        // give it a margin
        $('.chosenRep').css('margin-top', '86px');

        app.chooseLetter();
        app.chooseArtist();

    })
}

{/* <li class="songListItem">Mrs.Lovett</li> <li class="songListItem">Witch</li> */}



// submit form
app.submitForm = function () {
    var height = $('form').css('height');

    $('.sendForm').on('click', function(e){
        e.preventDefault();

     
    
            var $form = $('#form');
            $.post($form.attr("action"), $form.serialize()).then(function () {
                $('.formContent').addClass('hideForm');
                $('.formSubmitted').removeClass('hideForm').addClass('fadeIn');
    
                //find initial height of formSubmitted
                var smallHeight = $('.formSubmitted').css('height');
    
                $('.formSubmitted').css('height', height);
    
                setTimeout(function () {
                    $('.formSubmitted').removeClass('fadeIn').css('height', smallHeight);
                }, 200);
            });

    })// end of event

};





app.init = () => {
    app.scrollingEvent();
    app.scrollEffects();
    app.readMoreBio();
    app.viewMoreCal();
    app.viewMoreStage();
    app.navigateRight();
    app.swipeLeft();
    app.navigateLeft();
    app.swipeRight();
    app.leftRightArrows();
    app.openGalleryOverlay();
    app.viewPortrait();
    app.viewStage();
    app.LoadOverlay();
    app.closeGalleryOverlay();
    app.escapeGalleryOverlay();
    app.viewNews();
    app.viewQuotes();
    app.viewMoreNews();
    app.viewMoreQuotes();
    app.chooseLetter();
    app.chooseArtist();
    app.chooseSong();
    app.chooseOpera();
    app.chooseOra();
    app.chooseMus();
    app.navOverlayButton();
    app.submitForm();
    app.textAreaSize = () => {
        $('textarea').removeClass('ui-textinput ui-corner-all ui-shadow-inset ui-textinput-text ui-body-inherit ui-textinput-autogrow');
    }
}

$(function () {
    app.init();
});